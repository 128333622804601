:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.ReflectionPage {
  padding: 0 1.42857rem 5.71429rem 1.42857rem; }
  @media (min-width: 768px) {
    .ReflectionPage {
      padding: 1.42857rem 1.42857rem 5.71429rem 5.71429rem;
      max-width: 600px; } }
  @media (min-width: 1024px) {
    .ReflectionPage {
      padding: 1.42857rem 1.42857rem 5.71429rem 11.42857rem;
      max-width: 800px; } }
  .ReflectionPage__breadcrumb {
    padding-bottom: 1.42857rem; }
    .ReflectionPage__breadcrumb a[href^="/"] {
      color: #EE7B0C;
      text-decoration: none; }
      .ReflectionPage__breadcrumb a[href^="/"]:hover {
        color: #d66f0b; }
      .ReflectionPage__breadcrumb a[href^="/"]:focus {
        color: #be620a; }
    .ReflectionPage__breadcrumb .fa-angle-right {
      padding: 0 8px; }
  .ReflectionPage br {
    line-height: 150%; }
  @media (min-width: 768px) {
    .ReflectionPage__reflection {
      max-width: 600px; } }
  @media (min-width: 1024px) {
    .ReflectionPage__reflection {
      max-width: 800px; } }
  .ReflectionPage__reflection__info {
    display: block;
    padding-bottom: 0.71429rem; }
  .ReflectionPage__reflection__title {
    display: block;
    padding-bottom: 8px; }
  .ReflectionPage__reflection hr {
    margin-bottom: 1.42857rem; }
  .ReflectionPage__reflection sup {
    vertical-align: top;
    font-size: 0.75rem; }
  .ReflectionPage__reflection a:not([href^="/"]) {
    color: #EE7B0C;
    text-decoration: none; }
    .ReflectionPage__reflection a:not([href^="/"]):hover {
      background-color: #fdf2e7;
      border-bottom: 2px dashed #f5b06d;
      color: #d66f0b; }
    .ReflectionPage__reflection a:not([href^="/"]):focus {
      border-bottom: 2px dashed #be620a;
      color: #be620a; }
  .ReflectionPage__reflection a[href^="/"] {
    color: #EE7B0C;
    text-decoration: none; }
    .ReflectionPage__reflection a[href^="/"]:hover {
      color: #d66f0b; }
    .ReflectionPage__reflection a[href^="/"]:focus {
      color: #be620a; }
  .ReflectionPage__reflection img {
    width: 100%; }
  .ReflectionPage__tags hr {
    width: 100%;
    margin: 1.42857rem 0; }
  .ReflectionPage__tags__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap; }
    @media (min-width: 768px) {
      .ReflectionPage__tags__list {
        max-width: 500px; } }
    .ReflectionPage__tags__list__tag {
      border: 1px solid #424242;
      border-radius: 3px;
      color: #424242;
      padding: 2px 4px;
      margin-top: 16px;
      text-decoration: none;
      text-transform: capitalize; }
      .ReflectionPage__tags__list__tag:not(:last-child) {
        margin-right: 0.71429rem; }
      .ReflectionPage__tags__list__tag:hover {
        cursor: pointer;
        border: 1px solid #d66f0b;
        color: #d66f0b; }
      .ReflectionPage__tags__list__tag:focus {
        border: 1px solid #be620a;
        color: #be620a; }
  .ReflectionPage__nextPrevButtons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    margin-top: 1.42857rem; }
    .ReflectionPage__nextPrevButtons .Button:last-child {
      margin-left: 0.71429rem; }
