@import url("https://fonts.googleapis.com/css?family=Varela&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:700&display=swap");
@import url("https://use.fontawesome.com/releases/v5.7.2/css/all.css");
:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

html {
  background: #FAFAFA;
  padding: 0; }

html, body {
  width: 100%;
  margin: 0; }

body {
  color: #424242;
  font-size: 1rem; }
  body * {
    line-height: 1.25;
    font-family: 'Varela', 'Open Sans', sans-serif; }

h1, h2, h3, h4 {
  font-weight: normal;
  margin: 0; }

h1 {
  font-size: 2rem; }

h2 {
  font-size: 1.71429rem; }

h3 {
  font-size: 1.28571rem; }

h4 {
  font-size: 1.14286rem; }

hr {
  margin: 0; }

strong, b {
  font-family: 'Open Sans', sans-serif; }
