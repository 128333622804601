:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.Header {
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  background: #F5F5F5;
  height: 60px;
  width: 100%;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  -webkit-transition: height 200ms linear;
  -o-transition: height 200ms linear;
  transition: height 200ms linear; }
  @media (min-width: 768px) {
    .Header {
      height: 80px; } }
  @media print {
    .Header {
      display: none !important; } }
  .Header a {
    color: #616161;
    text-decoration: none;
    -webkit-transition: color 100ms linear, font-size 200ms linear;
    -o-transition: color 100ms linear, font-size 200ms linear;
    transition: color 100ms linear, font-size 200ms linear; }
    .Header a:hover {
      color: #212121; }
  .Header__name {
    display: inline-block;
    vertical-align: middle;
    padding-left: 1.42857rem; }
    @media (min-width: 768px) {
      .Header__name {
        padding-left: 5.71429rem; } }
    @media (min-width: 1024px) {
      .Header__name {
        padding-left: 11.42857rem; } }
    .Header__name a {
      font-size: 1rem; }
      @media (min-width: 1024px) {
        .Header__name a {
          font-size: 1.71429rem; } }
  .Header__right {
    display: none;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -ms-flex-align: center;
        align-items: center;
    padding-right: 1.42857rem;
    height: 100%; }
    @media (min-width: 768px) {
      .Header__right {
        display: -ms-flexbox;
        display: flex;
        padding-right: 5.71429rem; } }
    @media (min-width: 1024px) {
      .Header__right {
        padding-right: 11.42857rem; } }
    .Header__right a {
      text-decoration: none; }
      .Header__right a:not(:last-child) {
        margin-right: 10px; }
        @media (min-width: 768px) {
          .Header__right a:not(:last-child) {
            margin-right: 1.42857rem; } }
      .Header__right a.is-active {
        color: #d66f0b;
        pointer-events: none; }
  .Header.is-shrunk {
    height: 40px; }
    @media (min-width: 768px) {
      .Header.is-shrunk .Header__name a {
        font-size: 1.28571rem; } }
  .Header__hamburger {
    display: inline-block;
    margin-right: 1.42857rem; }
    .Header__hamburger:hover .fa-bars {
      color: #d66f0b; }
    @media (min-width: 768px) {
      .Header__hamburger {
        display: none; } }
