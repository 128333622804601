:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.Input {
  background-color: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #212121;
  font-size: 1rem;
  padding: 5px 8px; }
  .Input::-webkit-input-placeholder {
    color: #757575; }
  .Input::-moz-placeholder {
    color: #757575; }
  .Input::-ms-input-placeholder {
    color: #757575; }
  .Input::placeholder {
    color: #757575; }
