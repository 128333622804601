:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.BlogPage {
  overflow: hidden; }
  .BlogPage__head {
    padding: 0 1.42857rem 5.71429rem 1.42857rem;
    padding-bottom: 0; }
    @media (min-width: 768px) {
      .BlogPage__head {
        padding: 1.42857rem 1.42857rem 5.71429rem 5.71429rem;
        max-width: 600px; } }
    @media (min-width: 1024px) {
      .BlogPage__head {
        padding: 1.42857rem 1.42857rem 5.71429rem 11.42857rem;
        max-width: 800px; } }
    @media (min-width: 768px) {
      .BlogPage__head {
        padding-bottom: 0; } }
  @media (min-width: 768px) {
    .BlogPage__intro {
      max-width: 600px; } }
  @media (min-width: 1024px) {
    .BlogPage__intro {
      max-width: 800px; } }
  @media (min-width: 768px) {
    .BlogPage__getNotified {
      max-width: 600px; } }
  @media (min-width: 1024px) {
    .BlogPage__getNotified {
      max-width: 800px; } }
  .BlogPage__getNotified__form {
    margin-bottom: -0.71429rem; }
    .BlogPage__getNotified__form form {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
    .BlogPage__getNotified__form .Input {
      max-width: 300px;
      width: 100%;
      margin: 0 0.71429rem 0.71429rem 0; }
    .BlogPage__getNotified__form .Button {
      margin: 0 0.71429rem 0.71429rem 0; }
  .BlogPage__thankful {
    display: inline-block;
    line-height: 32px;
    vertical-align: middle;
    -webkit-animation: fade-in-bottom ease 1200ms;
            animation: fade-in-bottom ease 1200ms; }
  .BlogPage__cards {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    padding: 0 1.42857rem 5.71429rem 1.42857rem;
    margin: -0.71429rem; }
    @media (min-width: 768px) {
      .BlogPage__cards {
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
        -ms-flex-pack: start;
            justify-content: flex-start;
        padding: 0 2.85714rem 5.71429rem 5.71429rem; } }
    @media (min-width: 1024px) {
      .BlogPage__cards {
        padding: 0 2.85714rem 5.71429rem 11.42857rem; } }
    .BlogPage__cards .BlogCard {
      margin: 0.71429rem; }
  .BlogPage__empty {
    padding: 0 1.42857rem 5.71429rem 1.42857rem;
    padding-top: 0 !important; }
    @media (min-width: 768px) {
      .BlogPage__empty {
        padding: 1.42857rem 1.42857rem 5.71429rem 5.71429rem;
        max-width: 600px; } }
    @media (min-width: 1024px) {
      .BlogPage__empty {
        padding: 1.42857rem 1.42857rem 5.71429rem 11.42857rem;
        max-width: 800px; } }
