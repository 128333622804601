:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.ReflectionsPage {
  padding: 0 1.42857rem 5.71429rem 1.42857rem; }
  @media (min-width: 768px) {
    .ReflectionsPage {
      padding: 1.42857rem 1.42857rem 5.71429rem 5.71429rem;
      max-width: 600px; } }
  @media (min-width: 1024px) {
    .ReflectionsPage {
      padding: 1.42857rem 1.42857rem 5.71429rem 11.42857rem;
      max-width: 800px; } }
  .ReflectionsPage .Select {
    max-width: 100px;
    margin-bottom: 1.42857rem; }
  .ReflectionsPage__list__reflection:not(:last-child) {
    padding-bottom: 16px; }
  .ReflectionsPage__list__reflection a[href^="/"] {
    color: #EE7B0C;
    text-decoration: none; }
    .ReflectionsPage__list__reflection a[href^="/"]:hover {
      color: #d66f0b; }
    .ReflectionsPage__list__reflection a[href^="/"]:focus {
      color: #be620a; }
  .ReflectionsPage__list__reflection a {
    padding-right: 8px; }
  .ReflectionsPage__list__reflection__info {
    display: inline-block;
    white-space: nowrap; }
  .ReflectionsPage br {
    line-height: 150%; }
