:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.Books {
  overflow-x: scroll !important; }
  @media (min-width: 768px) {
    .Books {
      overflow-x: auto; } }
  .Books table {
    border-collapse: collapse; }
    @media (min-width: 768px) {
      .Books table {
        width: 560px; } }
    @media (min-width: 1024px) {
      .Books table {
        width: 800px; } }
    .Books table thead {
      border-bottom: 1px solid #BDBDBD; }
      .Books table thead tr {
        height: 32px; }
        .Books table thead tr th {
          font-weight: normal; }
    .Books table tbody td {
      padding: 16px; }
  .Books__book {
    text-align: center; }
    .Books__book a:not([href^="/"]) {
      color: #EE7B0C;
      text-decoration: none; }
      .Books__book a:not([href^="/"]):hover {
        background-color: #fdf2e7;
        border-bottom: 2px dashed #f5b06d;
        color: #d66f0b; }
      .Books__book a:not([href^="/"]):focus {
        border-bottom: 2px dashed #be620a;
        color: #be620a; }
    .Books__book__cover {
      max-height: 150px; }
    .Books__book__tags {
      font-size: 0.85714rem; }
    .Books__book__tag {
      display: block;
      text-transform: capitalize; }
      .Books__book__tag:not(:first-child) {
        margin-top: 14px; }
      .Books__book__tag a {
        color: #424242;
        border: 1px solid #424242;
        border-radius: 3px;
        padding: 2px 4px;
        text-decoration: none; }
        .Books__book__tag a:hover {
          border: 1px solid #d66f0b;
          color: #d66f0b;
          cursor: pointer; }
        .Books__book__tag a:focus {
          border: 1px solid #be620a;
          color: #be620a; }
