:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.TagsPage {
  padding: 0 1.42857rem 5.71429rem 1.42857rem; }
  @media (min-width: 768px) {
    .TagsPage {
      padding: 1.42857rem 1.42857rem 5.71429rem 5.71429rem; } }
  @media (min-width: 1024px) {
    .TagsPage {
      padding: 1.42857rem 1.42857rem 5.71429rem 11.42857rem; } }
  .TagsPage h1 {
    padding-bottom: 1.42857rem; }
  .TagsPage h3 {
    padding-bottom: 1.42857rem;
    text-transform: capitalize; }
  .TagsPage .Select {
    max-width: 300px;
    margin-bottom: 1.42857rem; }
  .TagsPage .Books {
    margin-bottom: 1.42857rem; }
  .TagsPage__books__empty {
    display: block;
    padding: 8px 0; }
  .TagsPage__reflections {
    padding-top: 1.42857rem; }
    .TagsPage__reflections__reflection {
      display: block; }
      .TagsPage__reflections__reflection:not(:last-child) {
        padding-bottom: 16px; }
      .TagsPage__reflections__reflection a[href^="/"] {
        color: #EE7B0C;
        text-decoration: none; }
        .TagsPage__reflections__reflection a[href^="/"]:hover {
          color: #d66f0b; }
        .TagsPage__reflections__reflection a[href^="/"]:focus {
          color: #be620a; }
      .TagsPage__reflections__reflection a {
        padding-right: 8px; }
      .TagsPage__reflections__reflection__info {
        display: inline-block;
        white-space: nowrap; }
    .TagsPage__reflections__empty {
      display: block;
      padding-top: 8px; }
