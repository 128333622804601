:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.NotFoundPage {
  padding: 0 1.42857rem 5.71429rem 1.42857rem; }
  @media (min-width: 768px) {
    .NotFoundPage {
      padding: 1.42857rem 1.42857rem 5.71429rem 5.71429rem;
      max-width: 600px; } }
  @media (min-width: 1024px) {
    .NotFoundPage {
      padding: 1.42857rem 1.42857rem 5.71429rem 11.42857rem;
      max-width: 800px; } }
