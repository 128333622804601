:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.BlogCard {
  position: relative;
  border-radius: 3px;
  border: 1px solid #616161;
  overflow: hidden;
  height: 240px;
  min-width: 280px;
  max-width: 400px;
  -webkit-transition: -webkit-transform 250ms ease-in-out;
  transition: -webkit-transform 250ms ease-in-out;
  -o-transition: transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out; }
  .BlogCard:hover {
    -webkit-box-shadow: 0 4px 8px #757575;
            box-shadow: 0 4px 8px #757575;
    cursor: pointer;
    -webkit-transform: translateY(-8px);
        -ms-transform: translateY(-8px);
            transform: translateY(-8px); }
  .BlogCard:focus-within {
    -webkit-box-shadow: 0 8px 16px #616161;
            box-shadow: 0 8px 16px #616161; }
  .BlogCard img {
    width: 100%; }
  .BlogCard__info {
    background-color: #FFFFFF;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.71429rem;
    width: 100%; }
    .BlogCard__info__title {
      color: #424242;
      display: block;
      font-size: 1.28571rem; }
    .BlogCard__info__date {
      color: #757575;
      display: block;
      font-size: 0.85714rem;
      padding-top: 4px; }
