:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.HomePage {
  padding: 0 1.42857rem 5.71429rem 1.42857rem; }
  @media (min-width: 768px) {
    .HomePage {
      padding: 1.42857rem 1.42857rem 5.71429rem 5.71429rem;
      max-width: 600px; } }
  @media (min-width: 1024px) {
    .HomePage {
      padding: 1.42857rem 1.42857rem 5.71429rem 11.42857rem;
      max-width: 800px; } }
  .HomePage a:not([href^="/"]) {
    color: #EE7B0C;
    text-decoration: none; }
    .HomePage a:not([href^="/"]):hover {
      background-color: #fdf2e7;
      border-bottom: 2px dashed #f5b06d;
      color: #d66f0b; }
    .HomePage a:not([href^="/"]):focus {
      border-bottom: 2px dashed #be620a;
      color: #be620a; }
  .HomePage a[href^="/"] {
    color: #EE7B0C;
    text-decoration: none; }
    .HomePage a[href^="/"]:hover {
      color: #d66f0b; }
    .HomePage a[href^="/"]:focus {
      color: #be620a; }
  .HomePage .Typist .Cursor--blinking {
    opacity: 1;
    -webkit-animation: blink 1s linear infinite;
            animation: blink 1s linear infinite; }

@-webkit-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .HomePage p {
    display: inline-block;
    position: relative;
    margin-top: 0; }
  .HomePage__main {
    -webkit-animation: fade-in-bottom ease 800ms;
            animation: fade-in-bottom ease 800ms; }
    .HomePage__main h3 {
      display: inline-block;
      position: relative;
      margin-bottom: 16px; }
