:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.Footer {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 0;
  background: #F5F5F5;
  height: 60px;
  width: 100%;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  -webkit-box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1; }
  @media print {
    .Footer {
      display: none !important; } }
  .Footer__contact {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    padding-left: 1.42857rem; }
    @media (min-width: 768px) {
      .Footer__contact {
        padding-left: 5.71429rem; } }
    @media (min-width: 1024px) {
      .Footer__contact {
        padding-left: 11.42857rem; } }
    .Footer__contact a:not(:first-child) {
      padding-left: 0.71429rem; }
      @media (min-width: 768px) {
        .Footer__contact a:not(:first-child) {
          padding-left: 1.42857rem; } }
    .Footer__contact a:hover {
      color: #d66f0b; }
      .Footer__contact a:hover .fab {
        color: #d66f0b; }
    .Footer__contact a:focus {
      color: #be620a; }
      .Footer__contact a:focus .fab {
        color: #be620a; }
    .Footer__contact a .fab {
      color: #757575;
      font-size: 1.28571rem;
      -webkit-transition: color 100ms linear;
      -o-transition: color 100ms linear;
      transition: color 100ms linear; }
      @media (min-width: 768px) {
        .Footer__contact a .fab {
          font-size: 1.5rem; } }
    .Footer__contact__text {
      display: none; }
      @media (min-width: 768px) {
        .Footer__contact__text {
          display: block;
          padding-left: 1.42857rem; } }
      .Footer__contact__text a {
        color: #757575;
        text-decoration: none; }
  .Footer__butterCMS {
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    max-width: 150px; }
    @media (min-width: 768px) {
      .Footer__butterCMS {
        padding: 0 2.85714rem;
        max-width: 219px; } }
    .Footer__butterCMS span {
      display: block;
      color: #9E9E9E;
      font-size: 0.85714rem; }
    .Footer__butterCMS img {
      display: inline-block;
      vertical-align: middle;
      max-width: 100px; }
  .Footer__copyright {
    color: #757575;
    font-size: 0.85714rem;
    margin-right: 1.42857rem; }
    @media (min-width: 768px) {
      .Footer__copyright {
        font-size: 1rem;
        margin-right: 2.85714rem; } }
    @media (min-width: 768px) {
      .Footer__copyright__initials {
        display: none; } }
    .Footer__copyright__name {
      display: none; }
      @media (min-width: 768px) {
        .Footer__copyright__name {
          display: inline-block; } }
