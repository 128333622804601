:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.MobileSideNav__wrapper {
  height: 100%;
  position: fixed;
  top: 0;
  left: 100%;
  -webkit-transition: -webkit-transform 0.4s ease-in-out 0s;
  transition: -webkit-transform 0.4s ease-in-out 0s;
  -o-transition: transform 0.4s ease-in-out 0s;
  transition: transform 0.4s ease-in-out 0s;
  transition: transform 0.4s ease-in-out 0s, -webkit-transform 0.4s ease-in-out 0s;
  width: 100%;
  z-index: 1000; }
  .MobileSideNav__wrapper.slide-left {
    -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
            transform: translateX(-100%); }
  @media (min-width: 768px) {
    .MobileSideNav__wrapper {
      display: none; } }

.MobileSideNav {
  background-color: #212121;
  float: right;
  height: 100%;
  position: relative;
  width: 80%;
  max-width: 400px;
  overflow: hidden; }
  .MobileSideNav__close {
    color: #EE7B0C;
    cursor: pointer;
    font-size: 2rem;
    height: 3rem;
    margin-top: 3px;
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem; }
    .MobileSideNav__close:hover {
      color: #d66f0b; }
    .MobileSideNav__close .fa.fa-angle-right {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
          -ms-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%); }
  .MobileSideNav__items {
    padding-top: 5.71429rem; }
  .MobileSideNav__item {
    border-top: 1px solid #EE7B0C;
    display: inline-block;
    font-size: 1.14286rem;
    font-weight: 500;
    padding: 10px 0 10px 1.42857rem;
    text-align: left;
    text-decoration: none;
    width: 100%; }
    .MobileSideNav__item.is-active {
      pointer-events: none; }
      .MobileSideNav__item.is-active span {
        color: #d66f0b; }
    .MobileSideNav__item:last-child {
      border-bottom: 1px solid #EE7B0C; }
    .MobileSideNav__item span {
      color: #F5F5F5; }
    .MobileSideNav__item:hover span {
      color: #d66f0b; }

.MobileSideNav__noScroll {
  overflow: hidden; }
  .MobileSideNav__noScroll.iOS {
    position: fixed;
    height: 100%;
    width: 100%; }
