:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.BlogPostPage {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 1.42857rem 5.71429rem 1.42857rem;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .BlogPostPage {
      margin: 0 5.71429rem; } }
  @media (min-width: 1024px) {
    .BlogPostPage {
      margin: 0 11.42857rem; } }
  .BlogPostPage a:not([href^="/"]) {
    color: #EE7B0C;
    text-decoration: none; }
    .BlogPostPage a:not([href^="/"]):hover {
      background-color: #fdf2e7;
      border-bottom: 2px dashed #f5b06d;
      color: #d66f0b; }
    .BlogPostPage a:not([href^="/"]):focus {
      border-bottom: 2px dashed #be620a;
      color: #be620a; }
  .BlogPostPage .Loader {
    padding: 1.42857rem; }
  .BlogPostPage__content {
    width: 100%; }
    @media (min-width: 768px) {
      .BlogPostPage__content {
        max-width: 720px;
        width: auto; } }
    @media (min-width: 1024px) {
      .BlogPostPage__content {
        max-width: 800px;
        width: auto; } }
    .BlogPostPage__content img {
      width: 100%; }
      @media (min-width: 768px) {
        .BlogPostPage__content img {
          padding-top: 1.42857rem; } }
    .BlogPostPage__content h1 {
      padding-top: 8px; }
    .BlogPostPage__content__date {
      color: #757575;
      padding: 4px 0 8px 0; }
    .BlogPostPage__content__body p {
      font-size: 1.14286rem; }
    .BlogPostPage__content__body img {
      width: 100%; }
    .BlogPostPage__content__body sup {
      vertical-align: text-top;
      font-size: 0.75rem; }
    .BlogPostPage__content__body sub {
      vertical-align: text-bottom;
      font-size: 0.75rem; }
    .BlogPostPage__content__body a {
      word-wrap: break-word; }
