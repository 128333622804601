:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.NotesPage {
  padding: 0 1.42857rem 5.71429rem 1.42857rem; }
  @media (min-width: 768px) {
    .NotesPage {
      padding: 1.42857rem 1.42857rem 5.71429rem 5.71429rem;
      max-width: 600px; } }
  @media (min-width: 1024px) {
    .NotesPage {
      padding: 1.42857rem 1.42857rem 5.71429rem 11.42857rem;
      max-width: 800px; } }
  .NotesPage h1 {
    padding-bottom: 1.42857rem; }
  .NotesPage__goodreads {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 1.42857rem; }
    .NotesPage__goodreads span {
      display: inline-block;
      padding-right: 8px; }
    .NotesPage__goodreads__badge {
      border-radius: 3px;
      -webkit-box-shadow: 0 1px 2px #BDBDBD;
              box-shadow: 0 1px 2px #BDBDBD;
      opacity: 0;
      vertical-align: middle;
      -webkit-transition: opacity 200ms linear;
      -o-transition: opacity 200ms linear;
      transition: opacity 200ms linear; }
      .NotesPage__goodreads__badge.is-visible {
        opacity: 1; }
        .NotesPage__goodreads__badge.is-visible:hover {
          -webkit-box-shadow: 0 2px 3px #757575;
                  box-shadow: 0 2px 3px #757575; }
  .NotesPage__intro {
    display: block;
    padding-bottom: 0.71429rem; }
    .NotesPage__intro p {
      margin-top: 0; }
    @media (min-width: 768px) {
      .NotesPage__intro {
        max-width: 600px; } }
    @media (min-width: 1024px) {
      .NotesPage__intro {
        max-width: 800px; } }
  .NotesPage__books__empty {
    display: block;
    padding: 16px 0; }
  .NotesPage__searchBooks {
    position: relative;
    padding-bottom: 1.42857rem;
    padding-right: 1.42857rem; }
    .NotesPage__searchBooks .Input {
      width: 100%;
      max-width: 300px; }
      @media (min-width: 768px) {
        .NotesPage__searchBooks .Input {
          width: 300px; } }
    .NotesPage__searchBooks .fa-times {
      display: none;
      position: absolute;
      top: 7px;
      left: 278px;
      font-size: 1.28571rem;
      color: #757575; }
      @media (min-width: 768px) {
        .NotesPage__searchBooks .fa-times {
          display: block; } }
      .NotesPage__searchBooks .fa-times:hover {
        cursor: pointer;
        color: #212121; }
