:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.AppLayout {
  position: relative;
  overflow-x: hidden;
  margin-top: 80px;
  min-height: calc(100vh - 60px); }
  @media (min-width: 768px) {
    .AppLayout {
      min-height: calc(100vh - 80px); } }
  .AppLayout.is-shrunk {
    min-height: calc(100vh - 40px); }
