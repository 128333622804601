:export {
  orange: #EE7B0C;
  orange100: #fce5ce;
  orange300: #f5b06d;
  orange900: #8f4a07;
  white: #FFFFFF; }

@-webkit-keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.KeelingCurvePage {
  padding: 0 1.42857rem 5.71429rem 1.42857rem; }
  @media (min-width: 768px) {
    .KeelingCurvePage {
      padding: 1.42857rem 1.42857rem 5.71429rem 1.42857rem; } }
  .KeelingCurvePage__keelingCurve img {
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-top: 1.42857rem; }
  .KeelingCurvePage__keelingCurve__text {
    padding: 0 1.42857rem;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .KeelingCurvePage__keelingCurve__text {
        padding: 0 8.57143rem;
        max-width: 600px; } }
    @media (min-width: 1024px) {
      .KeelingCurvePage__keelingCurve__text {
        padding: 0 11.42857rem;
        max-width: 800px; } }
    .KeelingCurvePage__keelingCurve__text a:not([href^="/"]) {
      color: #EE7B0C;
      text-decoration: none; }
      .KeelingCurvePage__keelingCurve__text a:not([href^="/"]):hover {
        background-color: #fdf2e7;
        border-bottom: 2px dashed #f5b06d;
        color: #d66f0b; }
      .KeelingCurvePage__keelingCurve__text a:not([href^="/"]):focus {
        border-bottom: 2px dashed #be620a;
        color: #be620a; }
    .KeelingCurvePage__keelingCurve__text a[href^="/"] {
      color: #EE7B0C;
      text-decoration: none; }
      .KeelingCurvePage__keelingCurve__text a[href^="/"]:hover {
        color: #d66f0b; }
      .KeelingCurvePage__keelingCurve__text a[href^="/"]:focus {
        color: #be620a; }
  .KeelingCurvePage__keelingCurve__source {
    font-size: 11px;
    padding: 1.42857rem 0;
    text-align: center;
    word-wrap: break-word; }
